import React from "react";

function Links() {
  return (
    <div className="links">
      <div class="container scroll">
        <div class="row align-items-center my-5"></div>
        <h1>Useful Links</h1>
        <h2>
          Allotments Information <i class="fa-solid fa-person-digging"></i>
        </h2>
        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.nsalg.org.uk/"
              target="_blank"
              rel="noreferrer"
            >
              National Allotment Society
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.rhs.org.uk/advice/grow-your-own/allotments"
              target="_blank"
              rel="noreferrer"
            >
              RHS Allotments
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.gov.uk/apply-allotment"
              target="_blank"
              rel="noreferrer"
            >
              Apply for an allotment (Nationally - UK)
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://lewisham.gov.uk/myservices/environment/allotments/apply-for-an-allotment"
              target="_blank"
              rel="noreferrer"
            >
              Apply for an allotment (Lewisham Borough Residents)
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://lewisham.gov.uk/myservices/environment/allotments"
              target="_blank"
              rel="noreferrer"
            >
              Lewisham allotments and community gardens
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://lewisham.gov.uk/myservices/environment/~/link.aspx?_id=21A693D64ED14F3EB2EE870B6B4B42B9&_z=z"
              target="_blank"
              rel="noreferrer"
            >
              Lewisham allotment sites and waiting times
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.gardenorganic.org.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Garden Organic
            </a>
          </div>
        </div>
        <h2>CCO Community Partnerships <i class="fa-solid fa-handshake"></i></h2>
        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">
            <a
              href="https://chinbrookmeadows.wordpress.com/"
              target="_blank"
              rel="noreferrer"
            >
              Friends of Chinbrook Meadows
            </a>
          </div>

          <div class="border rounded p-2 m-2">
            <a
              href="https://www.marvelslane.lewisham.sch.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Marvels Lane Primary School
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://woodcraft.org.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Woodcraft Folk
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://chartbiglocal.org.uk/"
              target="_blank"
              rel="noreferrer"
            >
              ChART Big Local
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://natureconservationlewisham.co.uk/how-to/natures-gym-2/"
              target="_blank"
              rel="noreferrer"
            >
              Nature's Gym
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://natureconservationlewisham.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Nature Conservation Lewisham
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.theorchardproject.org.uk/what-we-do/project-a-celebration-of-orchards/orchard-profiles/chinbrook-community-orchard/"
              target="_blank"
              rel="noreferrer"
            >
              The Orchard Project
            </a>
          </div>
        </div>
        <h2>
          Orchard Information <i class="fa-solid fa-apple-whole"></i>
        </h2>
        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">
            <a
              href="http://www.nationalfruitcollection.org.uk"
              target="_blank"
              rel="noreferrer"
            >
              National Fruit Collection
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.keepers-nursery.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              Keepers Nursery
            </a>
          </div>

          <div class="border rounded p-2 m-2">
            <a
              href="https://www.fruitid.com/#main"
              target="_blank"
              rel="noreferrer"
            >
              FruitID
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.orangepippin.com/varieties/apples/coxs-orange-pippin"
              target="_blank"
              rel="noreferrer"
            >
              Orange Pippin
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://tomtheappleman.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              Tom The Apple Man
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.frankpmatthews.com"
              target="_blank"
              rel="noreferrer"
            >
              Frank P Matthews
            </a>
          </div>
        </div>

        <h2>
          Seeds <i class="fa-solid fa-seedling"></i>
        </h2>

        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.realseeds.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Real Seeds
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://vitalseeds.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Vital Seeds
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.ethicalorganicseeds.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Ethical Organic Seeds
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.growseed.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Growseed
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://tamarorganics.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Tamar Organics
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.organicseedspecialists.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Organic Seed Specialists
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.organicpotatoes.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Leary's Organic Seeds
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://britishwildflowermeadowseeds.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              British Wildflower Seeds
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://seedcooperative.org.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Seed Co-operative
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.openpollinated.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Heirloom & Perennial
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.greenmanure.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Green Manure
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://learntosaveseeds.com/"
              target="_blank"
              rel="noreferrer"
            >
              How to save seeds
            </a>
          </div>
        </div>
        <h2>
          Wildlife & Conservation <i class="fa-solid fa-dove"></i>
        </h2>

        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.wildlifetrusts.org/gardening"
              target="_blank"
              rel="noreferrer"
            >
              The Wildlife Trusts
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.rspb.org.uk/birds-and-wildlife/advice/gardening-for-wildlife/"
              target="_blank"
              rel="noreferrer"
            >
              RSPB
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.wildaboutgardens.org.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Wild About Gardens
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.rhs.org.uk/gardening-for-the-environment/planet-friendly-gardening-tips"
              target="_blank"
              rel="noreferrer"
            >
              RHS - 10 ways to be more sustainable in your garden
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="hhttps://www.rhs.org.uk/science/conservation-biodiversity/wildlife/encourage-wildlife-to-your-garden"
              target="_blank"
              rel="noreferrer"
            >
              RHS - Encourage wildlife to your garden
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.nationalparkcity.london/"
              target="_blank"
              rel="noreferrer"
            >
              London National Park City
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.woodlandtrust.org.uk/blog/2019/09/how-to-build-a-bug-hotel/"
              target="_blank"
              rel="noreferrer"
            >
              Woodland Trust - how to build a bug hotel
            </a>
          </div>
          <div class="border rounded p-2 m-2">
            <a
              href="https://www.bto.org/how-you-can-help/providing-birds/putting-nest-boxes-birds/make-nest-box"
              target="_blank"
              rel="noreferrer"
            >
              BTO - Make a nest box
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Links;
