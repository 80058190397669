import React from "react";

function Recipes() {
  return (
    <div className="recipes">
      <div class="container scroll">
        <div class="row align-items-center my-5">
          <h1>Recipes</h1>
          <p>Watch this space...</p>
        </div>
      </div>
    </div>
  );
}

export default Recipes;
