import React from "react";
import Photo1 from "../images/gallery/1.jpg";
import Photo2 from "../images/gallery/2.jpg";
import Photo3 from "../images/gallery/3.jpg";
import Photo4 from "../images/gallery/4.jpg";
import Photo5 from "../images/gallery/5.jpg";
import Photo6 from "../images/gallery/6.jpg";
import Photo7 from "../images/gallery/7.jpg";
import Photo8 from "../images/gallery/8.jpg";
import Photo9 from "../images/gallery/9.jpg";
import Photo10 from "../images/gallery/10.jpg";
import Photo11 from "../images/gallery/11.jpg";
import Photo12 from "../images/gallery/12.jpg";
import Photo13 from "../images/gallery/13.jpg";
import Photo14 from "../images/gallery/14.jpg";
import Photo15 from "../images/gallery/15.jpg";
import Photo16 from "../images/gallery/16.jpg";
import Photo17 from "../images/gallery/17.jpg";
import Photo18 from "../images/orchard-sunset.jpg";
import Photo19 from "../images/orchard-snow.jpg";
import Halloween from "../images/halloween.jpg";

function Gallery() {
  return (
    <div className="gallery">
      <div class="container scroll">
        <div class="row align-items-center my-5">
          <h1>Photo Gallery</h1>

          <p class="photo-gallery">
            <img src={Photo1} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo2} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo7} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo3} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo4} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo5} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo6} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo8} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo9} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo10} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo11} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo12} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo13} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo15} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo16} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo14} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo18} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo19} alt="gallery" class="gal-photo img-fluid" />
            <img src={Photo17} alt="gallery" class="gal-photo img-fluid" />
            <img src={Halloween} alt="gallery" class="gal-photo img-fluid" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
