import React from "react";
import Photo1 from "../images/allotment-min.jpg";
import Photo2 from "../images/allotment-sunset.jpg";
import Photo3 from "../images/orchard-hut.jpg";

function Home() {
  return (
    <div className="home">
      <div class="container scroll">
        <div class="row align-items-center my-5">
          <h1>Chinbrook Meadows Horticultural Society</h1>
          <p>
            Chinbrook Meadows Horticultural Society is a
            self managed Horticultural Society in South East London. The site is
            located within the London Borough of Lewisham.
          </p>
          <p>
            The site was formerly farm land, part of the Baring Estate. In the
            early twentieth century this land became part of Chinbrook Meadows,
            an extensive area of open space. Later on the southern part of
            Chinbrook Meadows was turned into allotments and in 1991 the nature
            reserve and community orchard were created on the allotment site.
          </p>

          <p class="photo-gallery">
            <img src={Photo1} alt="chinbrook" class="mini-photo img-fluid" />
            <img
              src={Photo2}
              alt="chinbrook-sunset"
              class="mini-photo img-fluid"
            />
          </p>
        </div>
        <h2>Our Purpose</h2>
        <p>
          The purpose of the Chinbrook Meadows Horticultural Society allotments
          site is to provide for:
          <div class="d-flex flex-column justify-content-around flex-wrap boxes">
            <div class="border rounded p-2 m-2">
              Gardening: cultivating flowers, fruit and vegetables solely for
              the consumption of family and friends.
            </div>
            <div class="border rounded p-2 m-2">
              Social Capital: to ameliorate loneliness and enable citizens to
              contribute to society, plot-holders are part of a community of
              like-minded people.
            </div>
            <div class="border rounded p-2 m-2">
              Mental well-being: gardening plays a role in both preventing and
              alleviating mental ill-health.
            </div>
            <div class="border rounded p-2 m-2">
              Physical activity: helping plot-holders to keep fit.
            </div>
            <div class="border rounded p-2 m-2">
              Fresh, local, seasonal produce: giving access to fresh fruit and
              vegetables over the year.
            </div>
            <div class="border rounded p-2 m-2">
              Sense of achievement: growing vegetables requires acquiring new
              knowledge and skills.
            </div>
            <div class="border rounded p-2 m-2">
              Contact with nature: experiencing the seasons, witnessing the
              behaviour of birds, insects and other animals and gaining an
              understanding of the eco-system. This appreciation of the natural
              world also has the potential to inspire more environmentally aware
              behaviour by individuals.
            </div>
            <div class="border rounded p-2 m-2">
              Provide habitats for local species: protecting and improving
              existing habitats, particularly the orchard which is a Site of
              Importance for Nature Conservation, to allow biodiversity to
              thrive, which will also benefit site users.
            </div>
          </div>
        </p>
        <img src={Photo3} alt="orchard-hut" class="home-photo img-fluid" />
      </div>
    </div>
  );
}

export default Home;
