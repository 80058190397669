import React from "react";

function Location() {
  return (
    <div className="location">
      <div class="container scroll">
        <div class="row align-items-center my-5"></div>
        <h1>Location</h1>
        <p>
          Please note, everyday access to the site is restricted to allotment
          members.
        </p>
        <h2>
          Driving/Cycling <i class="fa-solid fa-bicycle"></i>
          <i class="fa-solid fa-car"></i>
        </h2>
        <p>
          If using satellite navigation please target Grove Park Cemetery
          (Marvels Lane, London SE12 9PU). Continue past the cemetery entrance
          and you will reach the entrance gate to the allotment site. There are
          several free car parking spaces available to the left of the allotment
          entrance gate. There are also bicycle racks available for use inside
          the site.
        </p>
        <h2>
          Bus <i class="fa-solid fa-bus-simple"></i>
        </h2>
        <p>
          The local area is served by the following buses:
          <div className="d-sm-flex justify-content-around boxes ">
            <div className="border rounded p-2 m-2 tech-item text-center ">
              284
            </div>
            <div className="border rounded p-2 m-2 tech-item text-center">
              124
            </div>
            <div className="border rounded p-2 m-2 tech-item text-center">
              126
            </div>
            <div className="border rounded p-2 m-2 tech-item text-center">
              273
            </div>
            <div className="border rounded p-2 m-2 tech-item text-center">
              624
            </div>
          </div>
          Bus stop locations can be checked on the{" "}
          <a
            href="https://tfl.gov.uk/maps/bus?Input=Grove+Park+Cemetery%2C+Marvels+Lane%2C+London%2C+UK&InputGeolocation=51.42462158203125%2C0.03640680015087128"
            target="_blank"
            rel="noreferrer"
          >
            Transport for London (TfL) website.
          </a>
        </p>
        <h2>
          Train <i class="fa-solid fa-train"></i>
        </h2>
        <p>
          The allotment site can be reached on foot from Grove Park train
          station in approximately 20 minutes. There are two walking routes from
          the station (1) via Chinbrook Meadows recreational ground or (2) via
          Chinbrook Road and Marvels Lane.
        </p>
        <p class="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.7141168571193!2d0.02828597327935258!3d51.42667800179391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe9548b5c2aa7a6fd!2sChinbrook%20allotment%20meadow!5e0!3m2!1sen!2suk!4v1664207319119!5m2!1sen!2suk"
            width="500"
            height="300"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="location-map"
            style={{
              border: 0,
            }}
          ></iframe>
        </p>
      </div>
    </div>
  );
}

export default Location;
