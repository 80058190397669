import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  About,
  Location,
  Orchard,
  Links,
  Conservation,
  Recipes,
  Gallery,
} from "./components";

ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/orchard" element={<Orchard />} />
      <Route path="/conservation" element={<Conservation />} />
      <Route path="/recipes" element={<Recipes />} />
      <Route path="/location" element={<Location />} />
      <Route path="/links" element={<Links />} />
      <Route path="/gallery" element={<Gallery />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);

serviceWorker.unregister();
