import React from "react";
import Photo1 from "../images/cat.jpg";
import Photo2 from "../images/planter.jpg";
import Vetchling from "../images/vetchling.png";
import Fox1 from "../images/wildlife/fox1.jpg";
import Fox2 from "../images/wildlife/fox2.jpg";
import Fox3 from "../images/wildlife/fox3.jpg";
import Toad from "../images/wildlife/toad.jpg";
import Slowworm from "../images/wildlife/slowworm.jpg";

function Conservation() {
  return (
    <div className="conservation">
      <div class="container scroll">
        <div class="row align-items-center my-5"></div>
        <h1>Conservation</h1>
        <p>
          The site's unique location and surroundings are key contributing
          factors for its abundance of plant and wildlife. The site is nestled
          between a number of other nature rich green locations which, in
          combination, provide a much broader area for wildlife to move between.
        </p>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            Chinbrook Meadows Park: grassland, mature trees, rivers and
            hedgerows.
          </li>
          <li class="list-group-item">
            Elmstead Woods: a very large area of ancient woodland.
          </li>
          <li class="list-group-item">
            Grove Park Cemetery: grassland flowers and many mature trees.
          </li>
          <li class="list-group-item">Sunridge Park Golf Course</li>
          <li class="list-group-item">
            Adjacent Wooded Railway Cutting: this acts as a green corridor for
            wildlife and plants.
          </li>
          <li class="list-group-item">Household Gardens</li>
        </ul>

        <h2>Local Biodiversity</h2>
        <h5>Birds</h5>
        <p>
          The Royal Society for the Protection of Birds (RSPB) provides
          excellent resources for the identification of birds which can be found
          on its
          <a
            href="https://www.rspb.org.uk/birds-and-wildlife/wildlife-guides/identify-a-bird/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            website
          </a>
          .
        </p>
        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">Blackbird</div>
          <div class="border rounded p-2 m-2">Blackcap</div>
          <div class="border rounded p-2 m-2">Blue Tit</div>
          <div class="border rounded p-2 m-2">Buzzard</div>
          <div class="border rounded p-2 m-2">Carrion Crow</div>
          <div class="border rounded p-2 m-2">Coal Tit</div>
          <div class="border rounded p-2 m-2">Chiffchaff</div>
          <div class="border rounded p-2 m-2">Collared Dove</div>
          <div class="border rounded p-2 m-2">Dunnock</div>
          <div class="border rounded p-2 m-2">Goldfinch</div>
          <div class="border rounded p-2 m-2">Greenfinch</div>
          <div class="border rounded p-2 m-2">Great Tit</div>
          <div class="border rounded p-2 m-2">Green Woodpecker</div>
          <div class="border rounded p-2 m-2">Great Spotted Woodpecker</div>
          <div class="border rounded p-2 m-2">House Sparrow</div>
          <div class="border rounded p-2 m-2">Herring Gull</div>
          <div class="border rounded p-2 m-2">Jay</div>
          <div class="border rounded p-2 m-2">Kestrel</div>
          <div class="border rounded p-2 m-2">Long Tailed Tit</div>
          <div class="border rounded p-2 m-2">Magpie</div>
          <div class="border rounded p-2 m-2">Ring-necked Parakeet</div>
          <div class="border rounded p-2 m-2">Robin</div>
          <div class="border rounded p-2 m-2">Rook</div>
          <div class="border rounded p-2 m-2">Starling</div>
          <div class="border rounded p-2 m-2">Stock Dove</div>
          <div class="border rounded p-2 m-2">Sparrowhawk</div>
          <div class="border rounded p-2 m-2">Swift</div>
          <div class="border rounded p-2 m-2">Willow Warbler</div>
          <div class="border rounded p-2 m-2">Wood Pigeon</div>
          <div class="border rounded p-2 m-2">Wren</div>
        </div>

        <h5>Mammals</h5>
        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">Foxes</div>
          <div class="border rounded p-2 m-2">Field Mouse</div>
          <div class="border rounded p-2 m-2">Wood Mice</div>
          <div class="border rounded p-2 m-2">Hedgehogs</div>
          <div class="border rounded p-2 m-2">Brown Rat</div>
        </div>

        <p class="photo-gallery">
          <img src={Fox1} alt="fox1" class="tiny-photo img-fluid" />
          <img src={Fox2} alt="fox2" class="tiny-photo img-fluid" />
          <img src={Fox3} alt="fox3" class="tiny-photo img-fluid" />
        </p>
        
        <h5>Reptiles & Amphibians</h5>
        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">Common Lizard</div>
          <div class="border rounded p-2 m-2">Slow Worms</div>
          <div class="border rounded p-2 m-2">Common Frog</div>
          <div class="border rounded p-2 m-2">Common Toad</div>
          <div class="border rounded p-2 m-2">Smooth Newt</div>
        </div>

        <p class="photo-gallery">
          <img src={Toad} alt="toad" class="tiny-photo img-fluid" />
          <img src={Slowworm} alt="slowworm" class="tiny-photo img-fluid" />
        </p>

        <h5>Invertebrates</h5>
        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">Gatekeeper Butterfly</div>
          <div class="border rounded p-2 m-2">Brimstone Butterfly</div>
          <div class="border rounded p-2 m-2">Meadow Brown Butterfly</div>
          <div class="border rounded p-2 m-2">Holly Blue Butterfly</div>
          <div class="border rounded p-2 m-2">Red Admiral Butterfly</div>
          <div class="border rounded p-2 m-2">Speckled Wood Butterfly</div>
          <div class="border rounded p-2 m-2">Stag Beetle</div>
          <div class="border rounded p-2 m-2">Bramble Leaf Miner Moth</div>
          <div class="border rounded p-2 m-2">Great Pond Snail</div>
          <div class="border rounded p-2 m-2">Red-tailed Bumblebee</div>
          <div class="border rounded p-2 m-2">Garden Bumblebee</div>
          <div class="border rounded p-2 m-2">Oak-tree Pug Moth</div>
          <div class="border rounded p-2 m-2">Lesser Yellow Underwing Moth</div>
          <div class="border rounded p-2 m-2">
            Broad-boarded Yellow Underwing Moth
          </div>
          <div class="border rounded p-2 m-2">Nut-tree Tussock Moth</div>
          <div class="border rounded p-2 m-2">Hebrew Character Moth</div>
          <div class="border rounded p-2 m-2">Least Black Arches Moth</div>
          <div class="border rounded p-2 m-2">Plume Moth</div>
          <div class="border rounded p-2 m-2">White-shouldered House-moth</div>
          <div class="border rounded p-2 m-2">Caddisfly</div>
          <div class="border rounded p-2 m-2">Large Red Damselfly</div>
          <div class="border rounded p-2 m-2">Common Blue Damselfly</div>
          <div class="border rounded p-2 m-2">Four-Spotted Chaser</div>
          <div class="border rounded p-2 m-2">Garden Spider</div>
          <div class="border rounded p-2 m-2">Crab Spider</div>
          <div class="border rounded p-2 m-2">Nursery Web Spider</div>
          <div class="border rounded p-2 m-2">Pirate Wolf Spider</div>
          <div class="border rounded p-2 m-2">Pardosa Spider</div>
          <div class="border rounded p-2 m-2">Woodlice</div>
          <div class="border rounded p-2 m-2">Blue Bow Fly</div>
          <div class="border rounded p-2 m-2">Mosquito</div>
          <div class="border rounded p-2 m-2">Harlequin Ladybird</div>
          <div class="border rounded p-2 m-2">Spot Ladybird</div>
          <div class="border rounded p-2 m-2">Earwig</div>
          <div class="border rounded p-2 m-2">Dock Bug</div>
          <div class="border rounded p-2 m-2">Western Conifer Seed Bug</div>
          <div class="border rounded p-2 m-2">Slug</div>
          <div class="border rounded p-2 m-2">Brown-lipped Snail</div>
          <div class="border rounded p-2 m-2">Common Red Ant</div>
          <div class="border rounded p-2 m-2">Honey Bee</div>
          <div class="border rounded p-2 m-2">Emperor Dragonfly</div>
          <div class="border rounded p-2 m-2">Azure Dragonfly</div>
          <div class="border rounded p-2 m-2">Common Blue Dragonfly</div>
          <div class="border rounded p-2 m-2">Large Red Chaser Dragonfly</div>
          <div class="border rounded p-2 m-2">Pond Skater</div>
          <div class="border rounded p-2 m-2">Leech</div>
          <div class="border rounded p-2 m-2">Broad Body Chaser</div>
          <div class="border rounded p-2 m-2">Thick-legged Flower Beetle</div>
        </div>

        <p class="photo-gallery">
          <img src={Photo1} alt="cat" class="mini-photo img-fluid" />
          <img src={Photo2} alt="planter" class="mini-photo img-fluid" />
        </p>

        <h5>Rare/Local Plants</h5>
        <div class="d-flex flex-row justify-content-around flex-wrap boxes">
          <div class="border rounded p-2 m-2">
            Grass Vetchling (Lathyrus Nissolia)
          </div>
          <div class="border rounded p-2 m-2">Wild Rose</div>
          <div class="border rounded p-2 m-2">Cow Parsley</div>
          <div class="border rounded p-2 m-2">Meadow Vetchling</div>
          <div class="border rounded p-2 m-2">Hairy Tare</div>
          <div class="border rounded p-2 m-2">Hawkbit</div>
          <div class="border rounded p-2 m-2">White Clover</div>
          <div class="border rounded p-2 m-2">Goose Grass</div>
          <div class="border rounded p-2 m-2">Bramble</div>
          <div class="border rounded p-2 m-2">Dock</div>
          <div class="border rounded p-2 m-2">Wood Avens</div>
          <div class="border rounded p-2 m-2">Common Speedwell</div>
          <div class="border rounded p-2 m-2">Cinque Flower</div>
          <div class="border rounded p-2 m-2">Cut Leaved Cranesbill</div>
          <div class="border rounded p-2 m-2">Scarlet Pimpernel</div>
          <div class="border rounded p-2 m-2">Cocksfoot</div>
          <div class="border rounded p-2 m-2">Timothy</div>
          <div class="border rounded p-2 m-2">Yorkshire Fog</div>
        </div>
        <p class="photo-gallery">
          <img src={Vetchling} alt="vetchling" class="tiny-photo img-fluid" />
        </p>
      </div>
    </div>
  );
}

export default Conservation;
