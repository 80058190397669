import React from "react";
import Photo1 from "../images/entrance-sign.jpg";
import Constitution from "../images/constitution.pdf";
import Aerial1 from "../images/aerial/aerial1.jpg";
import Aerial2 from "../images/aerial/aerial2.jpg";
import Aerial3 from "../images/aerial/aerial3.jpg";
import Aerial4 from "../images/aerial/aerial4.jpg";

function About() {
  return (
    <div className="about">
      <div class="container scroll">
        <div class="row align-items-center my-5"></div>
        <h1>About</h1>
        <h2>What is Chinbrook Meadows Horticultural Society?</h2>
        <p>
          We are a constituted Society whose core purpose is the encouragement
          of the cultivation of the allotments and management of the Chinbrook
          Meadows allotment site. We have a{" "}
          <a href={Constitution} target="_blank" rel="noreferrer">
            constitution{" "}
          </a>
          that sets out how the Society works. The rules of the Society and the
          constitution can only be changed at an Annual General Meeting (AGM).
          Everyone with a plot on our site automatically becomes a member of the
          Society and is invited to the AGM.
        </p>
        <p>
          We manage the site on behalf of the London Borough of Lewisham (the
          Council) which owns the site, but we are not part of the Council
          ourselves. We are not a charity and nor are we a business nor
          corporate organisation.
        </p>
        <p>
          The allotments sit around a small area of land that the Council has
          designated as a community orchard, but the Society and allotments are
          not themselves a community orchard.
        </p>
        <img
          src={Photo1}
          alt="entrance-sign"
          class="entrance-photo img-fluid"
        />
        <h2>The site</h2>
        <p>
          The Chinbrook Meadows allotment site has 71 plots. Our member
          community is a wonderfully diverse group of people from all over the
          London Borough of Lewisham.
        </p>
        <p>
          You can view current wait times for the site on the
          <a
            href="https://lewisham.gov.uk/myservices/environment/~/link.aspx?_id=21A693D64ED14F3EB2EE870B6B4B42B9&_z=z"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Lewisham Council website.{" "}
          </a>
          The council's allotments and community gardens team can also be
          contacted directly at{" "}
          <a href="mailto:allotments@lewisham.gov.uk">
            allotments@lewisham.gov.uk
          </a>
          .
        </p>
        <p class="map">
          <img src={Aerial1} alt="aerial1" class="img-fluid aerial-photo" />
          <img src={Aerial2} alt="aerial2" class="img-fluid aerial-photo" />
          <img src={Aerial3} alt="aerial3" class="img-fluid aerial-photo" />
          <img src={Aerial4} alt="aerial4" class="img-fluid aerial-photo" />
        </p>

        <h2>The committee</h2>
        <p>
          The Chinbrook Meadows committee members and officers are all unpaid
          volunteers who play an important role in how the site is organised
          today and what it will look like in the future.
        </p>
        <p>
          At present, the committee is made up of three officers, a chair,
          secretary and treasurer, as well as six additional committee members.
        </p>
        <p>
          The committee can be contacted by email at{" "}
          <a href="mailto:chinbrookmeadowsallotments@gmail.com">
            chinbrookmeadowsallotments@gmail.com.
          </a>
        </p>
      </div>
    </div>
  );
}

export default About;
