import React from "react";

function Footer() {
  return (
    <div className="footer">
      <footer class="py-3 bg-light fixed-bottom">
        <div class="container">
          <p class="m-0 text-center">
            <a
              href="https://www.facebook.com/ChinbrookMeadows/"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-facebook fa-xl"></i>
            </a>
            <a
              href="https://www.instagram.com/cc_orchard/"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-instagram fa-xl"></i>
            </a>
            <a
              href="mailto:chinbrookmeadowsallotments@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-regular fa-paper-plane fa-xl"></i>
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
