import React from "react";
import Photo1 from "../images/orchard.jpeg";
import Photo4 from "../images/orchard1.jpg";
import Photo5 from "../images/orchard2.jpg";
import Photo6 from "../images/orchard3.jpg";
import BeePhoto from "../images/bees.jpg";

function Orchard() {
  return (
    <div className="orchard">
      <div class="container scroll">
        <div class="row align-items-center my-5"></div>
        <h1>The Orchard</h1>
        <h2>What is the Chinbrook Orchard?</h2>
        <p>
          Planted in 1991 by Lewisham’s Nature Conservation Team, the orchard is
          designated as a Grade II Site of Borough Importance for Nature
          Conservation. Most of the trees are traditional (mostly Kentish)
          varieties of fruit trees, including cobnuts, plums, pears, cherries,
          walnuts and no fewer than 30 varieties of apples. Their names are
          wonderfully poetic – ‘Kentish Filbasket’, ‘Beauty of Kent’, ‘Decio’,
          ‘Allington Pippin’. A few more exotic species, including a cork oak,
          olives, walnuts, and stone pines, were also planted to reflect the
          borough’s ethnic diversity as well as a small demonstration vineyard.
        </p>
        <p>
          The grass between the fruit trees is managed as a hay meadow and cut
          rotationally every year to provide a habitat for wood mice, common
          lizards, slow worms, and a variety of invertebrates including stag
          beetles who all benefit from the various dead wood structures created
          in the orchard. Ant hills are also present in the grassland. The grass
          vetchling, an uncommon species in Lewisham, grows in profusion.
          Currently two allotment plot holders keep beehives in the orchard.
        </p>
        <p>
          Two ponds on the edge of the orchard are planted with a mix of native
          marginal and aquatic plants. Common frogs, common toads and smooth
          newts all breed in the ponds feeding in the surrounding tall
          grassland.
        </p>
        <p>
          The orchard is managed on a day-to-day basis for the London Borough of
          Lewisham by a dedicated group of allotment plot holders with an
          Orchard Sub-committee reporting to the Allotment Committee.
        </p>
        <p>
          <img src={Photo1} alt="orchard" class="orchard-photo img-fluid" />
        </p>
        <h2>Projects</h2>
        <p>
          Volunteers are currently participating in the citizen science
          <a
            href="https://bigbutterflycount.butterfly-conservation.org/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            The Big Butterfly Count
          </a>{" "}
          and
          <a
            href="https://www.nhm.ac.uk/take-part/monitor-and-encourage-nature/genepools.html"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Genepools 2023{" "}
          </a>
          projects. We are also supporting Butterfly Conservation’s
          <a
            href="https://butterfly-conservation.org/our-work/conservation-projects/england/big-city-butterflies"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Big City Butterflies{" "}
          </a>
          project and trying to encourage butterflies with the creation of a
          mini wildflower meadow, trialling an extra grass cut to increase
          wildflowers in the main orchard area, and later in the year we will be
          planting disease resistant elm and buckthorn. We also hope to be able
          to support Froglife’s
          <a
            href="https://www.froglife.org/london-blue-chain/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            London Blue Chain{" "}
          </a>
          project. In addition a volunteer is carrying out moth surveys.
        </p>
        <p>
          We are monitoring the outcomes of a project - “Getting rid of the
          hose” - completed in 2022 with funding from
          <a
            href="https://www.groundwork.org.uk/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Groundwork
          </a>
          . This allowed us, with help from our local primary school as part of
          their Life Cycles project work, to plant 3 new heritage apple trees
          thus contributing towards the sustainability of the orchard. The
          funding was also used to construct a rainwater catching structure for
          one of our wildlife ponds. The aim of this was to try and introduce an
          element of climate resilience as well as make effective use of
          increasingly variable rainwater whilst not interfering with the pond
          ecosystem which topping up with tap water does.
        </p>
        <p class="photo-gallery">
          <img
            src={Photo4}
            alt="orchard-plaques"
            class="mini-photo img-fluid"
          />
          <img src={Photo5} alt="orchard-pond" class="mini-photo img-fluid" />
          <img
            src={Photo6}
            alt="orchard-rainwater-harvest"
            class="mini-photo img-fluid"
          />
        </p>
        <h2>Bees</h2>
        Bees are important pollinators for flowers, fruit and vegetable crops.
        They help strengthen the biodiversity of the area where they live. At
        Chinbrook Meadows Allotments we have a number of honey bee hives in our
        Orchard area. You can find out about what to plant to help bees
        <a
          href="https://www.bbka.org.uk/Blogs/planting-for-bees-blog"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          here
        </a>
        .
        <p class="photo-gallery">
          <img src={BeePhoto} alt="bees" class="mini-photo img-fluid" />
        </p>
        <h2>Contact</h2>
        <p>
          The orchard sub-committee can be contacted by email at{" "}
          <a
            href="mailto:ccorchard@outlook.com"
            target="_blank"
            rel="noreferrer"
          >
            ccorchard@outlook.com.
          </a>
        </p>
      </div>
    </div>
  );
}

export default Orchard;
